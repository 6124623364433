
.icon-btn.operator:hover {
    padding: 1px 6px;
    background: #fff;
}

.icon-btn.selected, .icon-btn.operator.selected {
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 45px;
    padding: 0;
    margin: 0;
    background: none;
    border-radius: 100%;
    background: #ced4da;
}

.dashboard-empty-offers {
    font-size: 14px;
    padding-left: 10px;
    padding-top: 10px;
}

@media (max-width: 414px) {
    .datePickerCalendarWrapper{
        left: 5px;
        right: 5px;
    }
}
.filters .datePickerCalendarWrapper {
    position: fixed !important;
    left: auto;
}

.no-content-msg {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 24px;
    color: #878787;
    text-align: center;
    padding: 15px;
}

.MuiTypography-body1 {
    color: rgba(58,58,58,.87)!important;
    font-size: 14px!important;
}
.MuiInput-input {
    font-size: 14px!important;
}
.MuiSvgIcon-fontSizeSmall {
    color: #ccc;
}

.MuiChip-root {
    height: auto !important;
    margin-top: 0 !important;
}
.MuiChip-deleteIcon {
    margin-right: 2px !important;
}


.MuiPickersDay-daySelected ,.MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockNumber-clockNumberSelected, .MuiTabs-indicator {
    background-color: rgb(23, 162, 184) !important;
}

.MuiTabs-indicator {
    background-color: red !important;
}

.MuiPickersClockPointer-thumb {
    background-color: rgb(23, 162, 184) !important;
    border: 14px solid rgb(23, 162, 184) !important;
}

.MuiTypography-root.MuiPickersYear-root.MuiPickersYear-yearSelected.MuiTypography-h5.MuiTypography-colorPrimary {
    color: rgb(23, 162, 184) !important;
}

.MuiPickersToolbar-toolbar, .MuiPickerDTTabs-tabs{
    background-color: rgba(0,0,0,.07) !important;
}
.MuiPickersToolbarText-toolbarTxt, .MuiTab-wrapper {
    color: rgba(20, 20, 20, 0.54) !important;
}

@media screen and (max-width: 600px) {
.MuiSnackbar-anchorOriginBottomCenter {
    bottom: 60px !important;
}
}

body.theme-dark .MuiPickersCalendarHeader-iconButton {
background-color: #232628;
}

body.theme-dark .MuiPaper-root {
    background-color: #333533 ;
    color: #dcdcdc !important;
}

body.theme-dark .MuiPaper-root span{
    color: #dcdcdc !important;
}


body.theme-dark .MuiChip-root {
     color: rgba(0, 0, 0, 0.87);
     border: none;
     cursor: default;
     height: 32px;
     display: inline-flex;
     outline: 0;
     padding: 0;
     font-size: 0.8125rem;
     box-sizing: border-box;
     transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     align-items: center;
     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
     white-space: nowrap;
     border-radius: 16px;
     vertical-align: middle;
     justify-content: center;
     text-decoration: none;
     background-color: #333537;
 }

body.theme-dark .filters .MuiChip-root, body.theme-dark .MuiPopover-paper .MuiChip-root {
    border: 1px solid rgba(15, 15, 15, 0.87);
    background-color: #26282a;
}

body.theme-dark .MuiChip-label {
    color: #dcdcdc;
}

body.theme-dark .MuiChip-deleteIcon {
    color: #151617;
}

 .MuiFormLabel-root.Mui-focused {
    color: #f15a24 !important;
}
.MuiInput-underline:after {
 border-bottom-color: #f15a24 !important;
}
body.theme-dark .MuiInput-input input {
   color: #dcdcdc !important;
}


/*.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1 {
    font-size: 18px;
}

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-h4,
.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h3,
.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4 {
    font-size: 22px;
}*/
.MuiTextField-root input {
    min-width: auto !important;
}


.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar.MuiToolbar-gutters {
    display: none;
}

.input-error {
    box-shadow: 0 2px 2px rgba(240, 128, 128, 0.45) inset, 0 0 8px rgba(240, 128, 128, 0.67);
    outline: 0 none;
    border: 1px solid lightcoral !important;
}

input.input-error:focus {
    outline: none !important;
    box-shadow: 2px 0 10px #F08080;
    border: 1px solid lightcoral !important;
}

.error-msg {
    font-size: 12px;
    color: #e0413e;
    padding-left: 10px;
}

.circle-btn.selected {
    background: #ced4da;
}

/*Chart legend START*/
.po-chart-legends ul {
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 0;
    vertical-align: top;
}
.po-chart-legends li {
    cursor: pointer;
    list-style-type: none;
    user-select: none;
    line-height: 17px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 14px;
    padding-top: 3px;
}
.po-chart-legends .disabled {
    color: rgb(195, 195, 195);
    filter: grayscale(100%);
    background: none !important;
}

.po-chart-legends .disabled  span{
    background-color: white !important;
}

.po-chart-legends li span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border: 1px solid rgb(195, 195, 195);
}

@media screen and (max-width: 1024px) {
    ul.po-chart-legends.multiple-rows {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        -ms-column-count: 4;
        -o-column-count: 4;
        column-count: 4
    }
}

@media screen and (max-width: 865px) {
    ul.po-chart-legends.multiple-rows {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3
    }
}

@media screen and (max-width: 414px) {
    ul.po-chart-legends.multiple-rows {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        -ms-column-count: 2;
        -o-column-count: 2;
        column-count: 2
    }
}
/*Chart legend END*/

/*STATISTIC TABLE START*/

.statistic_table_wrapper {
    overflow-x: auto !important;
    overflow-y: auto !important;
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
}

table.statistic_table.sticky-header thead {
    position: relative;
    z-index: 1;
}

table.statistic_table.sticky-header thead th {
    padding: 10px 15px 10px 5px!important;
    vertical-align: middle;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    border: 0 transparent !important;
}

table tbody tr.sticky-action-row td {
    padding: 10px 15px 10px 5px!important;
    vertical-align: middle;
    position: sticky;
    position: -webkit-sticky;
    top: 38px;
    border: 0 transparent !important;
}

table.statistic_table.sticky-footer tfoot {
    position: sticky;
    position: -webkit-sticky;
}
@media screen and (max-width: 767px) {
    table.statistic_table.sticky-footer tfoot {
        bottom: -3px;
    }
}

table.statistic_table.sticky-footer tfoot th {
    padding: 10px 5px !important;
    white-space: nowrap !important;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
}

table.statistic_table.sticky-action #actionRow td {
    padding: 10px 15px 10px 5px!important;
    vertical-align: middle;
    position: sticky;
    position: -webkit-sticky;
    top: 38px;
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
}


body.theme-dark table.statistic_table.sticky-action #actionRow td{
    border-top: 1px solid #151617 !important;
    border-bottom: 1px solid #151617 !important;
    background-color: #333537;
    color: white;
    font-weight: bold;
}

table.statistic_table tr.grouped_row {
    transition: all ease 700ms;
}

table.statistic_table tr.grouped_row.opened {
    background: #dfdfdf;
}

body.theme-dark table.statistic_table tr.grouped_row.opened {
    background: #27292b !important
}

.grayTR td{
    background-color: #f1f1f1;
    /*z-index: 98;*/
}

body.theme-dark .grayTR td{
    background-color: rgb(21, 22, 23);
    /*z-index: 98;*/
}

table.statistic_table {
    margin-bottom: 0 !important;
}

table.statistic_table td {
    padding: 8px 10px;
}

table.statistic_table td i.fa.fa-chevron-right.statistic_table_icon {
    display: inline-table;
    transition: all ease 200ms;
    transform: none;
    font-size: 12px;
    color: #616161;
}

table.statistic_table i.fa, table.statistic_table i.ti {
    color: #616161;
}

body.theme-dark table.statistic_table td i.fa.fa-chevron-right.statistic_table_icon {
    color: #a1aab2;
}

body.theme-dark table.statistic_table i.fa, body.theme-dark table.statistic_table i.ti {
    color: #a1aab2;
}

table.statistic_table td i.fa.fa-chevron-right.statistic_table_icon.opened {
    transform: rotate(90deg);
}

thead .sorting, thead .sorting_asc, thead .sorting_desc, thead .sorting_asc_disabled, thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right
}

thead .sorting {
    background-image: url("../images/sort/sort_both.png")
}

thead .sorting_asc {
    background-image: url("../images/sort/sort_asc.png")
}

thead .sorting_desc {
    background-image: url("../images/sort/sort_desc.png")
}

tbody td.statistic_body {
    padding: 8px 10px !important;
    white-space: nowrap !important;
    top: 24px;
}

tbody td.statistic_body_group {
    white-space: nowrap !important;
    top: 24px;
}

tbody td.partners_body {
    padding: 8px 10px !important;
    top: 24px;
}

tbody td.partners_body_group {
    top: 24px;
}

.right_position {
    text-align: right;
}

.left_position {
    text-align: left;
}

.center_position {
    text-align: center;
}

.padding-5-10 {
    padding: 5px 10px;
}

.chip-button {
    background: #ffffff ;
    border: 1px solid #b7b7b7;
    border-radius: 15px;
    cursor: pointer;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 400;
    margin: 2px;
}

.chip-button.selected {
    background: #DFDFDF;
}

.chip-button:hover {
    background: #cecece;
    transition: all .25s ease;
}

.chip-button i {
    margin-right: 3px;
    color: #545454;
}

body.theme-dark .chip-button {
    background: #333537;
    border: 1px solid #151617;
    border-radius: 15px;
    cursor: pointer;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 400;
    margin: 2px;
}

body.theme-dark .chip-button i {
    color: #a1aab2;
}

@media (any-pointer: coarse) {
    .chip-button:hover  {
        background:  #ffffff;
    }
    .chip-button.selected {
        background: #DFDFDF;
    }
    body.theme-dark .chip-button:hover  {
        border:  1px solid #DFDFDF;
    }
    body.theme-dark .chip-button.selected {
        border: 1px solid #DFDFDF;
    }
}

body.theme-dark .chip-button.selected {
    border:  1px solid #DFDFDF;
}


.material-ui-indicator-container {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

/*Fix bug on the ios */
input, input:before, input:after {
    -webkit-user-select: initial !important;
    -khtml-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
    -o-user-select: initial !important;
}

.material-ui-select-group-row {
    display: inline;
}

/*.select-potok {
    margin: 2px 5px !important;
}*/

.page_not_found_outer:before {
    content: '';
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
}

.page_not_found_inner {
    display: inline-block;
    vertical-align: middle;
}

.page_not_found_outer {
    text-align: center;
}

.MuiPopover-paper {
    overflow-x: inherit !important;
    overflow-y: inherit !important;

}

.live-chat-time {
    font-size: 10px;
    opacity: 0.65;
    text-align: end;
    margin-bottom: -10px;
    margin-right: -5px;
}

.live-chat-edited {
    opacity: 0.65;
    font-size: 10px;
    text-align: start;
    margin-bottom: -15px;
    margin-left: -10px;
    margin-right: 30px;
}

textarea.se-wrapper-inner.se-wrapper-code {
    height: 100% !important;
    min-height: 35vh !important;
    max-height: 2000px !important;
}

button._se_tab_link {
    display: none;

}

.se-dialog-tabs {
    display: none;
}

.api-wrap {
    padding: 10px;
    flex-grow: 1;
    min-width: 100px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
}

.margin-left5 {
    margin-left: 5px;
}

body.theme-dark thead .sorting_asc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==) !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
}

body.theme-dark thead .sorting_desc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
}

body.theme-dark thead .sorting {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAABW0lEQVQ4T62UMS8EURDH/+Ml5CRX6HVUPoPmqrNu9raQK3SIQiIRCgpqCgoikSgEneKi2Ns566prfAYVnV4hcSF5RlYUi927vXXTvvf7vX8yM48wwKIButBT5rruqKruFQqFrXq9/t7t8Z4yZt5U1V0i2hCRk9wyz/OmrLUtAOMAHqy1M2EYPqYJuyUjZj4DsBSDj0VkHYAmCVNllUqlRETXAMZi4AuAWRG5yyyrVqtFVfVVtZQAtay1XhiGb7/PEpMx8yKAUwAfCbIhIloIguAqkyzv7CUmc113XlUv05IBWBGRi0zJHMcZMcb4AMp/AKI2EXmNRiNqxo9K7SYzTwO4AVCMEc+qOtdsNtuZu/l9MZqzIwBrMfBcRJb7nrNI4DjOhDHmFsAkgCdjTNn3/fs8G/DFMPOqqh4S0Y6IHOTezQis1WrDnU5nn4i2gyB4/Zesn5nr+QX1I/sEO8FxFMAgkK4AAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
}

.sun-editor .se-btn-tray {
    overflow: hidden;
}

.sun-editor .se-container {
    z-index: 1;
}

body.theme-dark .sun-editor * {
    background-color: #333537 !important;
}

body.theme-dark .sun-editor .se-btn-module-border {
    border: 1px solid #151617;
    color: white;
}


body.theme-dark .sun-editor,
body.theme-dark .se-toolbar .sun-editor-common,
body.theme-dark .sun-editor .se-list-layer,
body.theme-dark .sun-editor .se-resizing-bar,
body.theme-dark .sun-editor .se-toolbar,
body.theme-dark div[contenteditable=true] {
    border: 1px solid #151617;
}

body.theme-dark .sun-editor .se-tooltip {
    color: white;
}

body.theme-dark .sun-editor button {
    color: white;
}

body.theme-dark .sun-editor .se-btn-list.default_value {
    border-top: 1px dotted #151617;
    border-bottom: 1px dotted #151617;
}

body.theme-dark .sun-editor .se-wrapper .se-placeholder {
    left:10px;
    right: 10px;
}

body.theme-dark .datePickerCalendarWrapper {
    border: 1px solid #151617;
}

body.theme-dark .rdrCalendarWrapper,
body.theme-dark .rdrDays {
  background-color: #333537;
}

body.theme-dark .rdrDay.rdrDayDisabled  {
    background-color: #47494b;

}

body.theme-dark .rdrDay.rdrDayPassive .rdrDayNumber span {
    color: #6e767e !important;
}

body.theme-dark .rdrNextPrevButton {
    background-color: #0e0e0f;
    color: #0e0e0f;
    border: 1px solid #151617;
}

body.theme-dark .rdrNextButton i {
    border-color: transparent transparent transparent #a1aab2;
}
body.theme-dark .rdrPprevButton i {
    border-color: transparent  #a1aab2 transparent transparent;
}

body.theme-dark .rdrNextPrevButton i  {
    color: #0e0e0f;
}

body.theme-dark .rdrMonthAndYearPickers select {
    color: #a1aab2;
}

body.theme-dark svg.MuiSvgIcon-root  {
    background: transparent !important;
}

body.theme-dark svg.MuiCircularProgress-svg  {
    background: inherit !important;
}

table.se-table-size-auto tr {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .content table{
        margin-bottom: 40px !important;
    }
}

span.close {
    font-family: Red hat Display, sans-serif;
}

.search-icon i.ti.ti-search{
    position: inherit;
}

body.theme-dark .btn-secondary {
    color: #495057 !important;
}

.rules-body {
    height: 100vh !important;
    margin: 0;
    overflow: auto !important;
    width: 100%;
}

.rules-container {
    max-width: 960px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px;
    height: auto;
}

.rules-container h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 14px !important;
}

.rules-container p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 14px !important;
    line-height: 1.1;
}

.rules-container h1 {
    color: #fff;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin-top: 60px;
}

.progress-container {
    height: 100vh;
    position: fixed;
    right: 0;
    width: 8px;
    background: #333;
    z-index: 9;
    top: 0;
}


.rules-content {
    display: block;
    position: relative;
    height: auto;
    float: left;
    text-align: left;
    padding-bottom: 60px;
}

.rules-page.logo {
    margin-top: -10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
}

/* хром, сафари */
.rules-body::-webkit-scrollbar {
    display: none !important;
}

/* ie 10+ */
.rules-body {
    -ms-overflow-style: none !important;
}

/* фф (свойство больше не работает, других способов тоже нет)*/
.rules-body {
    overflow: -moz-scrollbars-none !important;
}

@media screen and (max-width: 768px) {
    .rules-body {

    }
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-100 {
    padding-top: 100px;
}

.cap-card {
    margin: 10px;
    padding-top: 5px;
    font-size: 14px;

}

.cap-name {
    font-weight:600;
    margin-right: 7px;
}

.cap-percent {
    margin-left: 7px;
    font-weight:500;
}

.cap-percent.low, body.theme-dark .MuiPaper-root span.cap-percent.low {
    color: #ff2a48 !important;
}

.cap-percent.mid, body.theme-dark .MuiPaper-root span.cap-percent.mid {
    color: #ff9218 !important;
}

.cap-percent.many, body.theme-dark .MuiPaper-root span.cap-percent.many {
    color: #3ac47d !important;
}

tr {
    transition: all ease 700ms;
}

tr.selected_row {
    background-color: rgb(241 90 36 / 31%) !important;
}

.empty-filters-block {
    z-index: 999;
    width: 10px;
    height: 100%;
    position: fixed;
    right: 0;
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 20px;
    gap: 20px;
    float: left;
    opacity: 0;
}

h6.offers-dynamic {
    padding: 27px;
}

.card-body.dashboard-offers-card {
    height: 65vh;
    display: block!important;
}

.filters .MuiFormControl-root  .multiselect input {
    width: 1px;
}

.table {background: #fff;}
.table .thead-light {
    -webkit-box-shadow: 0px 2px 2px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 2px 2px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 2px 2px 0px rgba(34, 60, 80, 0.2);

}
.table .thead-light th {
    color: #505050;
    background-color: #f6f6f6;
    border-color: #f6f6f6;
}

.nowrap-custom button{float:left;}

.filters .MuiFormControl-fullWidth {
    width: 95% !important;
}

.filters #datePicker .MuiInput-underline {
    width: 90% !important;
}

body .main-page-content{
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    position: fixed;
    /*font-family: Helvetica;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.txt {
    width: 65px;
    font-size: 10px;
    color: #f15a24;
}
.txt-search {
    width: 65px;
    font-size: 10px;
}

body.theme-dark .txt-search {
    color: #a1aab2;
}

.filters-btn, .setup-btn, .xls-btn, .ip-range-btn {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 5px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    float: left;
    position: relative;
    cursor: pointer;
}

.ip-range-icon {
    font-size: 26px;
    color: #000000cc;
}

body.theme-dark .chart-screen {
    background-color: #000;
}

.modal-offer-img {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Image size */
.modal-offer-img-content {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 500px;
}

/* Close button */
.modal-offer-img-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.modal-offer-img-close:hover,
.modal-offer-img-close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.padding_bottom-50 {
    padding-bottom: 50px;
}
